import React from 'react';
import './LandingPage.css'; // Assuming we use an external CSS file for styling

const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* Header Section */}
      <header className="header">
        <div className="logo">ENT Remote</div>
        <nav className="nav">
          <a href="#how-it-works">How it Works</a>
          <a href="#patients">For Patients</a>
          <a href="#doctors">For Doctors</a>
          <a href="#pricing">Pricing</a>
          <a href="#support">Support</a>
          <button className="cta-button">Start Your Remote Session</button>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <h1>Remote ENT Scoping, Anytime, Anywhere</h1>
        <p>Connect with your ENT doctor in real-time and get the care you need.</p>
        <div className="hero-images">
          <img src="ear_pic.webp" alt="Patient using remote scope" />
        </div>
        <button className="cta-button hero-button">Get Started</button>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works" id="how-it-works">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <img src="connect.webp" alt="Connect" />
            <h3>Connect</h3>
            <p>Join a secure video session with your doctor.</p>
          </div>
          <div className="step">
            <img src="scope.webp" alt="Scope" />
            <h3>Scope</h3>
            <p>Use the remote scope to examine your ears, nose, or throat with real-time video.</p>
          </div>
          <div className="step">
            <img src="diagnose.webp" alt="Diagnose" />
            <h3>Diagnose</h3>
            <p>Your doctor views the live feed and provides immediate feedback.</p>
          </div>
        </div>
      </section>

      {/* For Patients & Doctors Section */}
      <section className="info-section">
        <div className="info-column" id="patients">
          <h3>For Patients</h3>
          <p>Using our device is easy and convenient, giving you access to remote ENT care from the comfort of your home.</p>
        </div>
        <div className="info-column" id="doctors">
          <h3>For Doctors</h3>
          <p>View live data, access recorded sessions, and provide immediate feedback to your patients remotely.</p>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-cards">
          <div className="testimonial-card">
            <p>“The remote scoping technology made my consultation so easy, I could see exactly what the doctor was seeing!”</p>
            <span>- John, Patient</span>
          </div>
          <div className="testimonial-card">
            <p>“This technology has transformed the way I can treat my patients remotely. It’s incredibly convenient.”</p>
            <span>- Dr. Smith, ENT Specialist</span>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-links">
          <a href="#about">About Us</a>
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms of Service</a>
        </div>
        <div className="contact-info">
          <p>Support: support@entremote.com</p>
          <p>Phone: (555) 123-4567</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
